import React from "react";
import './css/SurveyTownModal.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as ft from '../utils/fetch';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function SurveyTownModal({ applyChange, surveyEmployeeID, isOpen, setSEID }) {

    // State Variables
    const [town, setTown] = React.useState('');
    const [townOptions, setTownOptions] = React.useState([]);

    // Handle Change Events
    const handleTownChange = (event) => {
        setTown(event.target.value);
    };

    // Method to Load Town Names
    const load_towns = async () => {
        try {
            let r = await ft.fetch_get('/get/surveypole/towns');
            if(r.data) { setTownOptions(r.data) }
        } catch(e) { console.warn(e) }
    }

    // Load Town Name Effect
    React.useEffect(() => { load_towns() }, []);

    return (
        <Modal
            open={isOpen}
            onClose={() => setSEID('')}
        >
            <Box sx={modalStyle}>
                <FormControl sx={{ width: '100%' }} >
                    <InputLabel id="packet-town-label">Town</InputLabel>
                    <Select
                        labelId="packet-town-label"
                        id="packet-town"
                        value={town}
                        label="Town"
                        onChange={handleTownChange}
                    >
                        {townOptions.map( town => <MenuItem value={town}>{town}</MenuItem> )}
                    </Select>
                </FormControl>
                <div className="p-control-apply-btn" onClick={() => applyChange(surveyEmployeeID, town)}>
                    <span className="p-control-apply-btn-text">
                        Apply
                    </span>
                </div>
            </Box>
        </Modal>
    );
}