import React from 'react';
import './css/Credentials.css';
import * as ft from '../utils/fetch';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FaSyncAlt } from 'react-icons/fa';
import { LuTextCursorInput } from 'react-icons/lu';

// Components
import UpdateCredentialModal from '../components/UpdateCredentialModal';


const columns = [
    { id: 'first_name', label: 'First', minWidth: 100 },
    { id: 'last_name', label: 'Last', minWidth: 80 },
    { id: 'username', label: 'Username', minWidth: 80 },
    { id: 'server_role', label: 'Role', minWidth: 80, align: 'center' },
    { id: 'update_btn', label: '', minWidth: 20, align: 'center' },
    { id: 'reset_btn', label: '', minWidth: 20, align: 'center' },
];

export default function Credentials() {
    
    // Page States
    const [loading, setLoading] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
    const [modalCredentials, setModalCredentials] = React.useState(null);
    const [modalUsername, setModalUsername] = React.useState(null);

    // Table Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Reset Password Method
    const reset_password = async (id, un) => {
        if(window.confirm('Reset Password for ' + un + '?')) {
            let result = await ft.fetch_post('/update/credentials/random', { username: un });
            if(result.result === 'success') { alert('Password Reset\nNew Password: ' + result.newPassword) }
            else { alert('ERROR: Try Again.') }
        }
    }

    // Update Password Name
    const update_password = async (id, un) => {
        console.log(`[${id}] update password for: ${un}`);
        await setModalCredentials(id);
        await setModalUsername(un);
        setUpdateModalOpen(true);
    }

    // Reset Password Button
    const reset_button = (id, un) => { return (
        <div title='Reset Password' className='btn-reset-container'>
            <FaSyncAlt 
                size={ 20 } 
                className='btn-reset' 
                onClick={ async () => { await reset_password(id, un) } } 
            />
        </div>
    )};

    const update_button = (id, un) => { return (
        <div title='Update Password' className='btn-reset-container'>
            <LuTextCursorInput 
                size={ 20 } 
                className='btn-update' 
                onClick={ async () => { await update_password(id, un) } } 
            />
        </div>
    )};

    // Add credential to table
    const addRow = (cred_obj, emp_obj = {}) => { setRows(rows => [ ...rows, { 
        first_name: emp_obj.first_name, 
        last_name: emp_obj.last_name, 
        username: cred_obj.username, 
        server_role: (emp_obj.server_role === 2 ? 
                <Chip label="Admin" color="error" />
            : (emp_obj.server_role === 1 ? 
                <Chip label="Supervisor" color="warning" />   
            : 
                <Chip label="Employee" color="success" />
            )),
        update_btn: update_button(cred_obj._id, cred_obj.username),
        reset_btn: reset_button(cred_obj._id, cred_obj.username),
    }])};

    // Fetch all neccesary data
    const load_data = async (first_name = '', last_name = '', username = '') => {
        // Clear Rows
        setRows([]);

        // Grab Bulk Data
        let cr = await ft.fetch_get('/get/credentials');
        let er = await ft.fetch_get('/get/employees');
        er = er.data;

        // Pair employees with credentials
        cr.data.forEach(async r => { addRow(r, er.find(o => o._id === r.employee_id)) });

        // Done Loading
        setLoading(false);
    };

    // Loading Data
    React.useEffect(() => {
        load_data();
    }, []);

    return (
        <div className="page-container">
            <div className="header">
                <span className="header-text">Credentials</span>
            </div>
            <div className="body">
            <div className='cred-table-container'>
                <Paper sx={{ width: '100%', overflow: 'scroll' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                </div>
            </div>
            <UpdateCredentialModal
                isOpen={updateModalOpen}
                close_method={setUpdateModalOpen}
                cred_id={modalCredentials}
                user_name={modalUsername}
            />
        </div>
    );
}