import React from "react";
import './css/SurveyReports.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as ft from '../utils/fetch';

export default function SurveyReports() {

    // State Variables
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [reportDate, setReportDate] = React.useState(new Date());
    const [reportList, setReportList] = React.useState([]);
 
    // Table Columns
    const columns = [
        { id: 'pole_number', label: 'Pole #', minWidth: 20 },
        { id: 'town', label: 'Town', minWidth: 80 },
        { id: 'state', label: 'State', minWidth: 10, align: 'center' },
        { id: 'report_date', label: 'Date', minWidth: 80, align: 'center' },
        //( role === 2 ? { id: 'delete_btn', label: '', minWidth: 20, align: 'center' } : ''),
    ];

    // Table Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Prepare an Excel document for download
    const gen_exl = async () => {
        if(reportList.length < 1) { return alert('No reports to export.') }
        else { window.open(`https://easapi.enviroarborsolutions.com/api/get/surveyreport/excel/${reportDate.toISOString()}`) }
    }

    // Add report to table
    const addRow = (obj) => { setRows(rows => [ ...rows, { 
        pole_number: obj.pole_number, 
        town: obj.town, 
        state: obj.state,
        report_date: obj.date,
    }])};

    // Method for loading in a new report set
    const load_reports = async () => {
        await setRows([]);
        
        let res = await ft.fetch_get('/get/polesurveys?date=' + reportDate.toISOString().split('T')[0]);

        if(res.data) { 
            await setReportList(res.data);
            res.data.forEach( r => addRow(r) );
        }
    }

    return (
        <div className="pr-container">
            <div className="pr-row">
                <LocalizationProvider dateAdapter={ AdapterDateFns }>
                    <DemoContainer components={ ['DatePicker'] } sx={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                        <DatePicker
                            label="Report Date"
                            value={ reportDate }
                            onChange={ (v) => setReportDate(v) }
                            onClose={ load_reports }
                        />
                    </DemoContainer>
                </LocalizationProvider>
                {/*<div className="pdf-button" onClick={ () => gen_pdf() }>
                    <span className="btn-text">Download PDF</span>
                </div>*/}
                <div className="excel-button" onClick={ () => gen_exl() }>
                    <span className="btn-text">Download Reports</span>
                </div>
            </div>
            <div className="pr-table-container">
                <Paper sx={{ width: '100%', overflow: 'scroll', border: '3px solid black' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
            {/*<div className="pr-row">
                <div className="excel-button" onClick={ () => gen_progress_report() }>
                    <span className="btn-text">Download Project Progress Report</span>
                </div>
            </div>*/}
        </div>
    )
}