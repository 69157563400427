import React from 'react';
import './screens/Map.css';
import { useLoadScript } from '@react-google-maps/api';
import Map from './screens/Map';
import { FaArrowLeft } from 'react-icons/fa';
import TextField from '@mui/material/TextField';

export default function PoleMap({setPage}) {

  // State Variables and Ref 
  const townFilterRef = React.useRef(null);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [poles, setPoles] = React.useState([]);
  const [mapKey, setMapKey] = React.useState(0);
  const [headerInfo, setHeaderInfo] = React.useState('Loading...');

  // Google API Load Script
  const loadScript = {
    googleMapsApiKey: 'AIzaSyCaZY4QqwABidvYdwYsDqVCqeUI7xRYmgI',
    libraries: ['marker']
  };

  const { isLoaded } = useLoadScript(loadScript);

  // Method for loading poles with applied filters
  const load_poles = async (town_filter) => {

    await setDataLoaded(false);

    try {
      let data = await fetch(`https://easapi.enviroarborsolutions.com/api/get/poles?town=${town_filter.toUpperCase()}`).then(r => r.json());
      if(data.data) { await setPoles(data.data); }
      await setHeaderInfo(`${town_filter === '' ? 'All' : town_filter} - ${data.data.length} poles`);
    } catch(e) { console.warn(e) }

    await setMapKey(town_filter);
    await setDataLoaded(true);
  }

  // Initial effect to load poles on open
  React.useEffect(() => {
    load_poles('');
  }, []);

  // Render the Controls and Map
  if(!isLoaded && !dataLoaded) { return <div>Loading...</div> }
  return (
    <div className="map-container">
      <div className="control-bar">
        <div className="left-control-bar">
          <div className="go-back-btn" onClick={() => setPage(0)}>
              <FaArrowLeft size={40} color={'green'} />
          </div>
          <span className='header-text'>{headerInfo}</span>
        </div>
        <div className="filter-options">
            <TextField
                InputProps={{ className: 'town-filter-field' }} 
                id="town" 
                inputRef={townFilterRef}
                label="Town" 
                variant="outlined" 
            />
            <div className="apply-filter-btn" onClick={() => load_poles(townFilterRef.current.value)}>
              <span className="apply-filter-btn-text">Apply</span>
            </div>
        </div>
      </div>
      <Map poles={poles} setPage={setPage} key={mapKey} />
    </div>
  );
}
