import React from 'react';
import './css/Surveys.css';
import SurveyTabs from '../components/SurveyTabs';
import SurveyEmployees from '../components/SurveyEmployees';
import SurveyReports from '../components/SurveyReports';

export default function Surveys({ role }) {

    const [currentTab, setTab] = React.useState(1);

    return (
        <div className="page-container">
            <div className="header">
                <span className="header-text">Pole Surveys</span>
            </div>
            <div className="body">
                <SurveyTabs currentTab={currentTab} setTab={setTab} />
                {currentTab === 1 ? <SurveyEmployees role={ role } /> : ''}
                {currentTab === 2 ? <SurveyReports role={ role } /> : ''}
            </div>
        </div>
    );
}