import React from 'react';
import './css/UpdateCredentialModal.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FaRegWindowClose } from 'react-icons/fa';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as ft from '../utils/fetch';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function UpdateCredentialModal({ isOpen, close_method, cred_id, user_name }) {

    // State Variables
    const [newPass, setNewPass] = React.useState('');
    const [newPass2, setNewPass2] = React.useState('');

    // Update password method
    const update_password = async () => {

        // Confirm new passwords match each other
        if(newPass !== newPass2) {
            return alert('Passwords do not match!');

        // Prompt user to confirm password is correct
        } else {
            if(window.confirm(`Update user ${user_name} with new password: \'${newPass}\' ?`)) {

                // Attempt to update password for given user
                try {
                    let result = await ft.fetch_post('/update/credentials', { username: user_name, password: newPass });

                    if(result.result === 'success') { alert('Success!') }
                    else { alert('ERROR: try again.') }
                } catch(e) { console.warn(e) }

                // Exit Modal now that password is updated
                close_method(false);
            }
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => close_method(false)}
        >
            <Box sx={modalStyle}>
                <div className="modal-container">
                    <div className="modal-row">
                        <Typography variant="h4" gutterBottom>Update Password for - { user_name }</Typography>
                        <FaRegWindowClose className='modal-close-icon' onClick={() => close_method(false)} title='Exit' size={40} />
                    </div>
                    <div className="modal-row">
                        <TextField
                            InputProps={{ className: 'text-field-input' }}
                            InputLabelProps={{ className: 'text-field-input' }}
                            className='text-field'
                            id="new_password" 
                            label="New Password" 
                            variant="outlined" 
                            type="password" 
                            value={newPass} 
                            onChange={(v) => setNewPass(v.target.value)}
                        />
                    </div>
                    <div className="modal-row">
                        <TextField
                            InputProps={{ className: 'text-field-input' }}
                            InputLabelProps={{ className: 'text-field-input' }}
                            className='text-field'
                            id="new_password_2" 
                            label="Confirm Password" 
                            variant="outlined" 
                            type="password" 
                            value={newPass2} 
                            onChange={(v) => setNewPass2(v.target.value)}
                        />
                    </div>
                    <div className="modal-row">
                        <Button sx={{margin:'20px', flex:1}} color='success' variant="contained" onClick={() => update_password()}>Update Password</Button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}