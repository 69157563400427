import React from 'react';
import './App.css';
import { useState } from 'react';
import SideNav from './components/SideNav';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as ft from './utils/fetch';

// Import Pages
import Home from './screens/Home';
import Employees from './screens/Employees';
import Credentials from './screens/Credentials';
import Forms from './screens/Forms';
import TimeClock from './screens/TimeClock';
import Login from './screens/Login';
import PoleTesting from './screens/PoleTesting';
import PoleMap from './polemap/PoleMap';
import Analytics from './screens/Analytics';
import Progress from './screens/Progress';
import Surveys from './screens/Surveys';

// Mui Theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#57961C'
    }
  }
});

function App() {
  const [page, setPage] = useState(0);
  const [auth, setAuth] = useState(1);
  const [emp_obj, setEmpObj] = useState({_id: null});
  const [isPole, setIsPole] = useState(false);
  const [isSurvey, setIsSurvey] = useState(false);

  const get_emp_obj = async (id) => {
    let r = await ft.fetch_get('/get/employees?id=' + id);
    if(r.data) {
      return r.data[0];
    } else {
      return { _id: null };
    }
  };

  const authenticate = async () => {
    
    // Query Authentication
    let r = await ft.fetch_get('/auth');
    
    // Grab employee Object if Authenticated
    if(r.success) { 
      if(r.userId) { 
        let o = await get_emp_obj(r.userId);
        await setEmpObj(o);
      }
      if(r.role >= 1) { setAuth(2); }
      else { return alert('UNAUTHORIZED') }
    } else { setAuth(1); }

    // Grab Pole Auth
    let pr = await ft.fetch_get('/poleauth');
    if(pr.success) { setIsPole(pr.pole) }
    else { setIsPole(false) }

    // Grab Survey Auth
    let sr = await ft.fetch_get('/surveyauth');
    if(sr.success) { setIsSurvey(sr.survey) }
    else { setIsSurvey(false) }
  };

  // Authenticating
  React.useEffect(() => {
    authenticate();
  }, []);

  if(auth === 0) { return }
  else if(auth === 1) { return <ThemeProvider theme={theme}><Login af={() => authenticate()} /></ThemeProvider> }
  else if(auth === 2) { return (
    <ThemeProvider theme={ theme }>
    <div className="App">
      { page !== 10 ? <SideNav at={authenticate} userObj={emp_obj} page={page} pageMethod={setPage} role={emp_obj.server_role} isPole={ isPole } isSurvey={ isSurvey } /> : '' }
      { page === 0 ? <Home role={emp_obj.server_role} /> : '' }
      { page === 1 ? <Employees getEmp={get_emp_obj} role={emp_obj.server_role} /> : '' }
      { page === 2 ? <Forms role={emp_obj.server_role} /> : '' }
      { page === 3 ? <TimeClock role={emp_obj.server_role} /> : '' }
      { page === 4 ? <Credentials role={emp_obj.server_role} /> : '' }
      { page === 5 ? <PoleTesting role={emp_obj.server_role} isPole={isPole} /> : '' }
      { page === 6 ? <Surveys role={emp_obj.server_role} isSurvey={isPole} /> : '' }
      { page === 7 ? <Analytics role={emp_obj.server_role} isPole={isPole} /> : '' }
      { page === 8 ? <Progress role={emp_obj.server_role} isPole={isPole} /> : '' }
      { page === 10 ? <PoleMap setPage={setPage} /> : '' }
    </div>
    </ThemeProvider>
  )}
}

export default App;