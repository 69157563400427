import React from 'react';
import './css/Login.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as ft from '../utils/fetch';

const StyledTextField = styled(TextField) ({
    '& label.Mui-focused': {
        color: 'green',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'green',
        },
        '&:hover fieldset': {
            borderColor: 'green',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'green',
        },
    },
});

export default function Login({ af }) {

    // States
    const [u, setU] = React.useState('');
    const [p, setP] = React.useState('');

    // Login Method
    const attempt_login = async () => {
        let name = await u.toLowerCase().trim();
        let r = await ft.fetch_post('/login', { username: name, password: p });
        if(r.success) { af(); }
        else { alert('Invalid Login'); }
    }

    return (
        <div className="container">
            <Card sx={{ width: 1/4, minWidth: 500, }}>
                <form id='loginForm' onSubmit={(f) => { f.preventDefault(); attempt_login(); }}>
                    <CardContent className='login-card'>
                        <Typography variant="h3" gutterBottom>Login</Typography>
                        <StyledTextField 
                            InputProps={{ className: 'text-field' }} 
                            id="username" 
                            label="Username" 
                            variant="outlined" 
                            value={u} 
                            onChange={(v) => setU(v.target.value)}
                        />
                        <StyledTextField 
                            InputProps={{ className: 'text-field' }} 
                            className="text-field" 
                            id="password" 
                            label="Password" 
                            variant="outlined" 
                            type="password" 
                            value={p} 
                            onChange={(v) => setP(v.target.value)}
                        />
                        <Button type='submit' color='success' variant="contained">Login</Button>
                    </CardContent>
                </form>
            </Card>
        </div>
    );
}