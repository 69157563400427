import React from 'react';
import './css/Home.css';

export default function Home() {
    return (
        <div className="page-container">
            <div className="header">
                <span className="header-text">Home</span>
            </div>
            <div className="body">
            </div>
        </div>
    );
}