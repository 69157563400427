import React from 'react';
import './css/PoleTesting.css';
import PoleTabs from '../components/PoleTabs';
import PoleTesters from '../components/PoleTesters';
import PoleReports from '../components/PoleReports';
import Poles from '../components/Poles';

export default function PoleTesting({ role }) {

    const [currentTab, setTab] = React.useState(1);

    return (
        <div className="page-container">
            <div className="header">
                <span className="header-text">Pole Testing</span>
            </div>
            <div className="body">
                <PoleTabs currentTab={currentTab} setTab={setTab} />
                {currentTab === 1 ? <PoleTesters role={ role } /> : ''}
                {currentTab === 2 ? <PoleReports role={ role } /> : ''}
                {currentTab === 3 ? <Poles role={ role } /> : ''}
            </div>
        </div>
    );
}