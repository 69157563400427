import React from "react";
import './css/EmployeePicker.css';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';
import { FaRegWindowClose } from 'react-icons/fa';
import * as ft from '../utils/fetch';

const StyledTextField = styled(TextField) ({
    '& label.Mui-focused': {
        color: 'green',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'green',
        },
        '&:hover fieldset': {
            borderColor: 'green',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'green',
        },
    },
});

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EmployeePicker({ employee, setEmployee, isOpen, setOpen }) {
    const [modalFN, setModalFN] = React.useState('');
    const [modalLN, setModalLN] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modalRows, setModalRows] = React.useState([]);

    const columns = [
        { id: 'first_name', label: 'First', minWidth: 100 },
        { id: 'last_name', label: 'Last', minWidth: 80 },
        { id: 'select_btn', label: '', minWidth: 20, align: 'center' },
    ];

    // Table Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const select_emp = (id) => {
        setEmployee(id);
        setOpen(false);
    }

    const select_button = (id) => {
        return ( 
            <div title='Select Employee' className='btn-delete-container'>
                <Chip label="Select" color="success" onClick={ () => select_emp(id) } />
            </div> 
        )
    };

    // Add employee to table
    const addModalRow = (obj) => { setModalRows(modalRows => [ ...modalRows, { 
        first_name: obj.first_name, 
        last_name: obj.last_name, 
        select_btn: select_button(obj._id),
    }])};

    // Load pole tester data
    const load_data = async () => {
        setModalRows([]);
        let r = await ft.fetch_get('/get/employees');
        r.data.forEach(async r => { 
            if(modalFN !== '' || modalLN !== '') {
                if(
                    ( modalFN === '' ? false : r.first_name.toLowerCase().includes(modalFN.toLowerCase())) || 
                    ( modalLN === '' ? false : r.last_name.toLowerCase().includes(modalLN.toLowerCase()))
                ) {
                    addModalRow(r);
                }
            }
        })
    }

    // Loading Effect
    React.useEffect(() => {
        load_data();
    }, [modalFN, modalLN]);

    return (
        <Modal
            open={isOpen}
            onClose={() => setOpen(false)}
        >
            <Box sx={modalStyle}>
                <div className="emppick-modal-container">
                    <div className="emppick-modal-row">
                        <span className="emppick-header-text">Employee Picker</span>
                        <FaRegWindowClose className='modal-close-icon' onClick={() => setOpen(false)} title='Exit' size={40} />
                    </div>
                    <div className="emppick-modal-row">
                        <StyledTextField
                            InputProps={{ className: 'text-field-input' }}
                            InputLabelProps={{ className: 'text-field-input' }}
                            className='text-field'
                            id="first_name" 
                            label="First Name" 
                            variant="outlined" 
                            value={modalFN} 
                            onChange={(v) => setModalFN(v.target.value)}
                        ></StyledTextField>
                        <StyledTextField
                            InputProps={{ className: 'text-field-input' }}
                            InputLabelProps={{ className: 'text-field-input' }}
                            className='text-field'
                            id="last_name" 
                            label="Last Name" 
                            variant="outlined" 
                            value={modalLN} 
                            onChange={(v) => setModalLN(v.target.value)}
                        ></StyledTextField>
                    </div>
                    <div className="emppick-modal-row">
                        <Paper sx={{ width: '100%', overflow: 'scroll', border: '3px solid black' }}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {modalRows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                                </TableCell>
                                            );
                                            })}
                                        </TableRow>
                                        );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={modalRows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}