import React from 'react';
import './css/SideNav.css';
import eas_logo from '../assets/eas.png';
import { FaHome, FaUserFriends, FaRegFileAlt, FaLink, FaClock, FaPowerOff, FaMap, FaPencilRuler, FaBinoculars } from 'react-icons/fa';
import { BsGraphUp } from "react-icons/bs";
import { ReactComponent as PoleIcon } from '../assets/utility_pole.svg';
import * as ft from '../utils/fetch';


export default function SideNav({ at, userObj, pageMethod, page, role, isPole, isSurvey }) {
    
    const logout = async () => {
        let r = await ft.fetch_get('/logout');
        if(r.success) { at(); }
    }

    return (
        <div className="side-bar-container">
            <div className="head">
                <img className="eas-logo" src={eas_logo} alt="" />
                <span className="head-header hidden-mobile"><span className='green-text'>EAS HUB{' '}</span>Dashboard</span>
            </div>
            <div className="body">
                <ul>
                    <li className={'link-container' + (page === 0 ? ' active' : '')} onClick={() => pageMethod(0)}>
                        <FaHome size={30} />
                        <span className='link-text hidden-mobile'>Home</span>
                    </li>
                    {(role >= 2 && isPole) ? <li className={'link-container' + (page === 5 ? ' active' : '')} onClick={() => pageMethod(5)}>
                        <PoleIcon className='pole-icon' />
                        <span className='link-text hidden-mobile'>Pole Testing</span>
                    </li> : <></>}
                    {(role >= 1 && isSurvey) ? <li className={'link-container' + (page === 6 ? ' active' : '')} onClick={() => pageMethod(6)}>
                        <FaBinoculars size={30} />
                        <span className='link-text hidden-mobile'>Surveys</span>
                    </li> : <></>}
                    {role >= 2 ? <li className={'link-container' + (page === 1 ? ' active' : '')} onClick={() => pageMethod(1)}>
                        <FaUserFriends size={30} />
                        <span className='link-text hidden-mobile'>Employees</span>
                    </li> : <></>}
                    {/*role >= 1 ? <li className={'link-container' + (page === 2 ? ' active' : '')} onClick={() => pageMethod(2)}>
                        <FaRegFileAlt size={30} />
                        <span className='link-text hidden-mobile'>Reports</span>
                    </li> : <></>*/}
                    {/*role >= 1 ? <li className={'link-container' + (page === 3 ? ' active' : '')} onClick={() => pageMethod(3)}>
                        <FaClock size={30} />
                        <span className='link-text hidden-mobile'>Time Clock</span>
                    </li> : <></>*/}
                    {role >= 2 ? <li className={'link-container' + (page === 4 ? ' active' : '')} onClick={() => pageMethod(4)}>
                        <FaLink size={30} />
                        <span className='link-text hidden-mobile'>Credentials</span>
                    </li> : <></>}
                    {role >= 2 ? <li className={'link-container' + (page === 7 ? ' active' : '')} onClick={() => pageMethod(7)}>
                        <BsGraphUp size={30} />
                        <span className='link-text hidden-mobile'>Analytics</span>
                    </li> : <></>}
                    {role >= 2 ? <li className={'link-container' + (page === 8 ? ' active' : '')} onClick={() => pageMethod(8)}>
                        <FaPencilRuler size={30} />
                        <span className='link-text hidden-mobile'>Progress</span>
                    </li> : <></>}
                    {/*(role >= 1 && isPole) ? <li className={'link-container' + (page === 10 ? ' active' : '')} onClick={() => pageMethod(10)}>
                        <FaMap size={30} />
                        <span className='link-text hidden-mobile'>Pole Map</span>
                    </li> : <></>*/}
                </ul>
            </div>
            <div className="footer">
                <div className="emp-name hidden-mobile">
                    <span className="name">{`${userObj.first_name} ${userObj.last_name}`}</span>
                    <span className="title">{userObj.title}</span>
                </div>
                <div className='emp-options' title='Logout'>
                    <FaPowerOff size={30} onClick={() => logout()} />
                </div>
            </div>
        </div>
    );
}