import React from 'react';
import './css/Forms.css';

// MUI Imports
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Components
import ExpenseReport from '../components/forms/ExpenseReport';

export default function Forms() {

    // State Variables
    const [formSelection, setFormSelection] = React.useState(0);

    return (
        <div className="page-container">
            <div className="header">
                <span className="header-text">Reports</span>
            </div>
            <div className="body">
                <div className='form-selection-bar'>
                    <FormControl sx={{ flex: 1 }} >
                        <InputLabel id="form-selection-form-field">Report</InputLabel>
                        <Select
                            labelId="form-selection-form-field"
                            id="form-selection"
                            value={ formSelection }
                            label="Report"
                            onChange={ (e) => setFormSelection(e.target.value) }
                        >
                            <MenuItem value={ 1 }> Expense Report </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="form-display-section">
                    { formSelection === 1 && <ExpenseReport /> }
                </div>
            </div>
        </div>
    );
}