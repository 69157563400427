import React from "react";
import './css/ExpenseReport.css';
import { FaDownload } from 'react-icons/fa';
import * as ft from '../../utils/fetch';

// Mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function ExpenseReport() {

    // State Variables
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [filterType, setFilterType] = React.useState('');
    const [reportList, setReportList] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // Table Columns
    const columns = [
        { id: 'date', label: 'Date', minWidth: 40 },
        { id: 'type', label: 'Expense Type', minWidth: 30 },
        { id: 'amount', label: 'Amount', minWidth: 20 },
        { id: 'description', label: 'Description', minWidth: 100 },
        { id: 'additional_information', label: 'Additional Information', minWidth: 100 },
    ];

    // Table Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Radio Group Method
    const typeFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    // Load Reports
    const load_reports = async () => {

        // Request Reports with Filters
        let reports = await ft.fetch_get(`/get/expensereport?start=${startDate.toISOString()}&end=${endDate.toISOString()}&type=${filterType}`);
        
        // If successful, first modify the values for visual appearance, then set the list
        if(reports.data) { 

            // Set the list with visually altered data for presentation
            setReportList(reports.data.map( report => {

                // Add dollar sign to amount if present
                if(report.amount) { report.amount = `$${report.amount}`}

                // Format Date
                if(report.date) {
                    let d = new Date(report.date);
                    report.date = d.toDateString();
                }

                // Format Expense Type
                if(report.type) {
                    if(report.type === 'gas_card') { report.type = 'Gas Card' }
                    if(report.type === 'amex') { report.type = 'Amex' }
                    if(report.type === 'personal') { report.type = 'Personal Reimbursement' }
                }

                return report;
            })); 
        }
        else { alert('ERROR: Failed to load expense reports.')}
    }

    // Download Report
    const download_excel = async () => {
        if(reportList.length < 1) { return alert('No reports to export.') }
        else { window.open(`https://easapi.enviroarborsolutions.com/api/get/expensereport/excel?start=${startDate.toISOString()}&end=${endDate.toISOString()}&type=${filterType}`) }
    }

    return (
        <div className="container-reports">
            <div className="expense-report-control-bar">
                <div className="expense-report-control-controls">
                    <LocalizationProvider dateAdapter={ AdapterDateFns }>
                        <DemoContainer components={ ['DatePicker'] } sx={{ marginRight: '10px' }}>
                            <DatePicker
                                label="Start Date"
                                value={ startDate }
                                onChange={ (v) => setStartDate(v) }
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={ AdapterDateFns }>
                        <DemoContainer components={ ['DatePicker'] } sx={{ marginRight: '10px' }}>
                            <DatePicker
                                label="End Date"
                                value={ endDate }
                                onChange={ (v) => setEndDate(v) }
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Expense Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={filterType}
                            onChange={typeFilterChange}
                        >
                            <FormControlLabel value="" control={<Radio />} label="All" />
                            <FormControlLabel value="gas_card" control={<Radio />} label="Gas Card" />
                            <FormControlLabel value="amex" control={<Radio />} label="Amex" />
                            <FormControlLabel value="personal" control={<Radio />} label="Reimbursement" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className="expense-report-control-apply">
                    <FaDownload size={30} color="#57961C" className="expense-report-download-btn" title="Download Report" onClick={ download_excel } />
                    <div className="expense-report-control-apply-btn" onClick={ load_reports }>
                        <span className="expense-report-control-apply-btn-text">Apply</span>
                    </div>
                </div>
            </div>
            <div className="expense-report-table">
                <Paper sx={{ width: '100%', overflow: 'scroll', border: '3px solid black' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {value }
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={reportList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </div>
    );
}