import React from 'react';
import './css/TimeClock.css';
import { FaDownload } from 'react-icons/fa';
import * as ft from '../utils/fetch';

// Mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


const temp_function = async (weekending) => {
    let start_date = new Date(weekending);
    let end_date = new Date(weekending);
    start_date.setDate(start_date.getDate() - 6);
    start_date.setHours(0, 0, 0, 0);
    end_date.setHours(0, 0, 0, 0);
    console.log(start_date.toISOString());
    console.log(end_date.toISOString());
    console.log(await ft.fetch_get(`/get/shifts?start=${start_date.toISOString()}&end=${end_date.toISOString()}`))
}

export default function TimeClock() {

    // State Variables
    const [weekending, setWeekending] = React.useState(new Date());

     // Download Report
    const download_excel = async () => {
        let start_date = new Date(weekending);
        let end_date = new Date(weekending);
        start_date.setDate(start_date.getDate() - 6);
        start_date.setHours(0, 0, 0, 0);
        end_date.setHours(0, 0, 0, 0);

        window.open(`https://easapi.enviroarborsolutions.com/api/get/shifts/excel?start=${start_date.toISOString()}&end=${end_date.toISOString()}`)
    }

    return (
        <div className="page-container">
            <div className="header">
                <span className="header-text">Time Clock</span>
            </div>
            <div className="body">
                <div className="time-clock-weekly-report-controls">
                    <div className="time-clock-weekending-selector">
                        <LocalizationProvider dateAdapter={ AdapterDateFns }>
                            <DemoContainer components={ ['DatePicker'] } sx={{ marginRight: 10 }}>
                                <DatePicker
                                    label="Week Ending"
                                    value={ weekending }
                                    onChange={ (v) => setWeekending(v) }
                                    shouldDisableDate={(date) => date.getDay() !== 6}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                    <div className="time-clock-weekending-download-btn">
                        <FaDownload 
                            size={40} 
                            color="#57961C" 
                            className="time-clock-download-weekending-btn" 
                            title="Download Report"
                            onClick={download_excel} 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}