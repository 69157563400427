import React from 'react';
import './css/EmployeeModal.css';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { FaRegWindowClose } from 'react-icons/fa';

const StyledTextField = styled(TextField) ({
    '& label.Mui-focused': {
        color: 'green',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'green',
        },
        '&:hover fieldset': {
            borderColor: 'green',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'green',
        },
    },
});

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EmployeeModal({ emp_obj, isOpen, close_method, save, update, isLoaded, setLoaded }) {
    const [modalFN, setModalFN] = React.useState('');
    const [modalLN, setModalLN] = React.useState('');
    const [modalTITLE, setModalTITLE] = React.useState('');
    const [modalPHONE, setModalPHONE] = React.useState('');
    const [modalEMAIL, setModalEMAIL] = React.useState('');
    const [modalRATE, setModalRATE] = React.useState('');
    const [modalROLE, setModalROLE] = React.useState('');

    const save_modal = () => {
        // Update emp_obj with values
        emp_obj.first_name = modalFN;
        emp_obj.last_name = modalLN;
        emp_obj.title = modalTITLE;
        emp_obj.phone_number = modalPHONE;
        emp_obj.email = modalEMAIL;
        emp_obj.rate = modalRATE;
        emp_obj.server_role = modalROLE;

        // Save if new employee
        if(emp_obj._id === 'New') {
            save(emp_obj);

        // Update if existing
        } else {
            update(emp_obj);
        
        }
    }

    React.useEffect(() => {
        if(isLoaded) { return; }
        if(emp_obj === null) {  }
        else {
            setModalFN(emp_obj.first_name ? emp_obj.first_name : '');
            setModalLN(emp_obj.last_name ? emp_obj.last_name : '');
            setModalTITLE(emp_obj.title ? emp_obj.title : '');
            setModalPHONE(emp_obj.phone_number ? emp_obj.phone_number : '');
            setModalEMAIL(emp_obj.email ? emp_obj.email : '');
            setModalRATE(emp_obj.rate ? emp_obj.rate : '');
            setModalROLE(emp_obj.server_role ? emp_obj.server_role : '');
            setLoaded(true);
        }
    })
    
    if(emp_obj === null) { return <></> }
    else {
        return (
            <Modal
                open={isOpen}
                onClose={() => close_method(false)}
            >
                <Box sx={modalStyle}>
                    <div className="modal-container">
                        <div className="modal-row">
                            <Typography variant="h4" gutterBottom>Employee - { emp_obj._id }</Typography>
                            <FaRegWindowClose className='modal-close-icon' onClick={() => close_method(false)} title='Exit' size={40} />
                        </div>
                        <div className="modal-row">
                            <StyledTextField
                                InputProps={{ className: 'text-field-input' }}
                                InputLabelProps={{ className: 'text-field-input' }}
                                className='text-field'
                                id="first_name" 
                                label="First Name" 
                                variant="outlined" 
                                value={modalFN} 
                                onChange={(v) => setModalFN(v.target.value)}
                            ></StyledTextField>
                            <StyledTextField
                                InputProps={{ className: 'text-field-input' }}
                                InputLabelProps={{ className: 'text-field-input' }}
                                className='text-field'
                                id="last_name" 
                                label="Last Name" 
                                variant="outlined" 
                                value={modalLN} 
                                onChange={(v) => setModalLN(v.target.value)}
                            ></StyledTextField>
                        </div>
                        <div className="modal-row">
                            <StyledTextField
                                InputProps={{ className: 'text-field-input' }}
                                InputLabelProps={{ className: 'text-field-input' }}
                                className='text-field'
                                id="title" 
                                label="Title" 
                                variant="outlined" 
                                value={modalTITLE} 
                                onChange={(v) => setModalTITLE(v.target.value)}
                            ></StyledTextField>
                            <StyledTextField
                                InputProps={{ className: 'text-field-input' }}
                                InputLabelProps={{ className: 'text-field-input' }}
                                className='text-field'
                                id="phone_number" 
                                label="Phone Number" 
                                variant="outlined" 
                                value={modalPHONE} 
                                onChange={(v) => setModalPHONE(v.target.value)}
                            ></StyledTextField>
                            <StyledTextField
                                InputProps={{ className: 'text-field-input' }}
                                InputLabelProps={{ className: 'text-field-input' }}
                                className='text-field'
                                id="email" 
                                label="Email" 
                                variant="outlined" 
                                value={modalEMAIL} 
                                onChange={(v) => setModalEMAIL(v.target.value)}
                            ></StyledTextField>
                        </div>
                        <div className="modal-row">
                            <StyledTextField
                                InputProps={{ className: 'text-field-input', startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                InputLabelProps={{ className: 'text-field-input' }}
                                className='text-field'
                                id="rate" 
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Hourly Rate" 
                                variant="outlined" 
                                value={modalRATE} 
                                onChange={(v) => setModalRATE(v.target.value)}
                            ></StyledTextField>
                            <FormControl className='text-field' fullWidth>
                                <InputLabel id="server_role_label" className='text-field-input'>Role</InputLabel>
                                <Select
                                    labelId="server_role_label"
                                    id="server_role"
                                    className='text-field-input'
                                    value={modalROLE}
                                    label="Role"
                                    onChange={(v) => setModalROLE(v.target.value)}
                                >
                                    <MenuItem value={0}>Employee</MenuItem>
                                    <MenuItem value={1}>Supervisor</MenuItem>
                                    <MenuItem value={2}>Admin</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="modal-row">
                            <Button sx={{margin:'20px', flex:1}} color='success' variant="contained" onClick={() => save_modal()}>Save</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        );
    }
}