import React from 'react';
import EmployeeModal from '../components/EmployeeModal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import * as ft from '../utils/fetch';
import { FaPencilAlt, FaTrashAlt, FaUserPlus } from 'react-icons/fa';
import './css/Employees.css';

export default function Employees({ getEmp, role }) {
    // Page States
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loading, setLoading] = React.useState(true);
    const [modalLoaded, setModalLoaded] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [modalObj, setModalObj] = React.useState(null);
    const [editModalOpen, setEditModalOpen] = React.useState(false);

    // Table Columns
    const columns = [
        { id: 'first_name', label: 'First', minWidth: 80 },
        { id: 'last_name', label: 'Last', minWidth: 80 },
        { id: 'title', label: 'Title', minWidth: 80 },
        { id: 'phone_number', label: 'Phone', minWidth: 80 },
        { id: 'email', label: 'Email', minWidth: 80 },
        { id: 'server_role', label: 'Role', minWidth: 80, align: 'center' },
        (role === 2 ? { id: 'edit_btn', label: '', minWidth: 20, align: 'center' } : '' ),
        (role === 2 ? { id: 'delete_btn', label: '', minWidth: 20, align: 'center' } : '' ),
    ];

    // Add employee Method
    const add_employee = async (obj) => { 
        try {
            if(
                obj.first_name === '' &&
                obj.last_name === '' &&
                obj.title === '' &&
                obj.phone_number === '' &&
                obj.email === '' &&
                obj.rate === '' &&
                obj.server_role === ''
            ) { return alert('You must have at least one field filled out.'); }

            delete obj._id;
            let r = await ft.fetch_post('/add/employee', obj);

            if(r.result !== 'success') {
                return alert('ERROR: Failed to add new employee.');
            } else {
                fetch_employees();
                alert('SUCCESS: New Employee Generated.\nUsername: ' + r.newUsername + '\nPassword: ' + r.newPassword);
            }

        } catch(e) { console.warn(e) }

        // Close the Modal
        setEditModalOpen(false);
     }

    // Update employee Method
    const update_employee = async (obj) => { 
        try {
            if(
                obj.first_name === '' &&
                obj.last_name === '' &&
                obj.title === '' &&
                obj.phone_number === '' &&
                obj.email === '' &&
                obj.rate === '' &&
                obj.server_role === ''
            ) { return alert('You must have at least one field filled out.'); }

            let r = await ft.fetch_post('/update/employee', obj);

            if(r.result !== 'success') {
                return alert('ERROR: Failed to update employee.');
            } else {
                fetch_employees();
            }

        } catch(e) { console.warn(e) }

        // Close the Modal
        setEditModalOpen(false);
     }

    // Remove employee Method
    const remove_employee = async (id) => { 
        if(window.confirm('Are you sure you want to delete employee ' + id + '?')) {
            try {

                // Delete Employee
                let r = await ft.fetch_delete('/remove/employee/' + id);
    
                if(r.result !== 'success') {
                    return alert('ERROR: ' + r.result);
                } else {
                    fetch_employees();
                }
    
            } catch(e) { console.warn(e) }
        }
     } 

    // Load Emp Data
    const load_employee = async (id) => {
        setModalObj(await getEmp(id));
        setModalLoaded(false);
        setEditModalOpen(true);
    }

    // Create Buttons
    const edit_button = (id) => { if( role === 2 ) {  
        return ( 
        <div title='Edit Employee' className='btn-edit-container'>
            <FaPencilAlt 
                size={20} 
                className='btn-edit' 
                onClick={async () => { 
                    await load_employee(id);
                }} 
            />
        </div> ) } else { return <></> }
    };

    const delete_button = (id) => { if( role === 2 ) {  
        return ( 
        <div title='Delete Employee' className='btn-delete-container'>
            <FaTrashAlt 
                size={20} 
                className='btn-delete' 
                onClick={async () => { 
                    remove_employee(id);
                }} 
            />
        </div> ) } else { return <></> }
    };
    
    // Add employee to table
    const addRow = (obj) => { setRows(rows => [ ...rows, { 
        first_name: obj.first_name, 
        last_name: obj.last_name, 
        title: obj.title, 
        phone_number: obj.phone_number,
        email: obj.email,
        server_role: (obj.server_role === 2 ? 
                <Chip label="Admin" color="error" />
            : (obj.server_role === 1 ? 
                <Chip label="Supervisor" color="warning" />   
            : 
                <Chip label="Employee" color="success" />
            )),
        edit_btn: edit_button(obj._id),
        delete_btn: delete_button(obj._id),
    }])};

    // Fetch all employee data and add their rows
    const fetch_employees = async (first_name = '', last_name = '', title = '', server_role = '') => {
        setRows([]);
        let r = await ft.fetch_get('/get/employees');
        r.data.forEach(r => addRow(r))
        setLoading(false);
    };

    // Loading Data
    React.useEffect(() => {
        fetch_employees();
    }, []);

    // Table Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="page-container">
            <div className="header">
                <span className="header-text">Employees</span>
                { role === 2 ? <FaUserPlus 
                    className='add-user-icon' 
                    size={40} 
                    title='Add Employee' 
                    color='green' 
                    onClick={() => { setModalObj({_id: 'New'}); setModalLoaded(false); setEditModalOpen(true); }}
                /> : <></>}
            </div>
            <div className="body">
                <div className='emp-table-container'>
                <Paper sx={{ width: '100%', overflow: 'scroll' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                </div>
            </div>
            <EmployeeModal 
                emp_obj={modalObj} 
                isOpen={editModalOpen} 
                close_method={setEditModalOpen} 
                save={add_employee}
                update={update_employee}
                isLoaded={modalLoaded}
                setLoaded={setModalLoaded}
            />
        </div>
    );
}