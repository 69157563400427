export async function fetch_get(url) {
    let fetch_url = 'https://easapi.enviroarborsolutions.com/api' + url;

    try {
        return await fetch(fetch_url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(r => r.json());
    } catch(e) { console.log(e) }
}

export async function fetch_post(url, obj) {
    let fetch_url = 'https://easapi.enviroarborsolutions.com/api' + url;

    try {
        return await fetch(fetch_url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        }).then(r => r.json());
    } catch(e) { console.log(e) }
}

export async function fetch_delete(url) {
    let fetch_url = 'https://easapi.enviroarborsolutions.com/api' + url;

    try {
        return await fetch(fetch_url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(r => r.json());
    } catch(e) { console.log(e) }
}