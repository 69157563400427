import React from 'react';
import './css/SurveyTabs.css';

export default function SurveyTabs({ currentTab, setTab }) {
    return (
        <div className='tab-container'>
            <div className={ currentTab === 1 ? 'tab tab-active' : 'tab' } onClick={ () => setTab(1) }>
                <span className="tab-text">Survey Employees</span>
            </div>
            <div className={ currentTab === 2 ? 'tab tab-active' : 'tab' } onClick={ () => setTab(2) }>
                <span className="tab-text">Reports</span>
            </div>
        </div>
    )
}