import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './css/Progress.css';
import * as ft from '../utils/fetch';

const sortPercentageString = (v1, v2) => {
    if(v1 === '100%') { return 1 }
    else if(v2 === '100%') { return -1 }
    else { return v1.localeCompare(v2) }
}

export default function Progress() {

    // State Variables
    const [townData, setTownData] = React.useState([]);

    // Table Columns
    const columns = [
        { field: 'town', headerName: 'Town', width: 250 },
        { field: 'poles', headerName: 'Total Poles', width: 125 },
        { field: 'full_remaining', headerName: 'Full Remaining', width: 125 },
        { field: 'visual_remaining', headerName: 'Visual Remaining', width: 125 },
        { 
            field: 'progress', 
            headerName: 'Progress', 
            width: 125, 
            type: 'string',
            sortComparator: sortPercentageString,
        },
    ];

    // Load Town Data
    const load_data = async () => {

        // Grab Town List
        let town_list = await ft.fetch_get('/get/pole/towns');
        if(town_list.data) { town_list = town_list.data }
        else { return }

        // Iterate over Towns
        for(let i = 0; i < town_list.length; i++) {

            // Grab town Poles
            let poles = await ft.fetch_get(`/get/poles?town=${town_list[i]}`);
            if(poles.data) { poles = poles.data }

            // Calculate Totals
            let total_count = poles.length;
            let full_remaining = poles.filter(pole => (pole.status !== 2 && pole.inspection_type === 'FULL')).length;
            let visual_remaining = poles.filter(pole => (pole.status !== 2 && pole.inspection_type === 'VISUAL')).length;
            let progress = `${Math.round((1 - ((full_remaining + visual_remaining) / total_count)) * 100)}%`;

            // Add leading 0 to single digit numbers
            if(progress.length < 3) { progress = `0${progress}` }

            // Edit Row Array to add town data
            setTownData(v => [...v, { 
                id: i, 
                town: town_list[i], 
                poles: total_count,
                full_remaining: full_remaining, 
                visual_remaining: visual_remaining,
                progress: progress
            }]);
        }
    }

    // React Effects
    React.useEffect(() => load_data(), []);

    return (
        <div className="page-container">
            <div className="header">
                <span className="header-text">Progress</span>
            </div>
            <div className="body">
            <DataGrid
                sx={{ margin: '20px' }}
                rows={townData}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 30 },
                    },
                }}
                pageSizeOptions={[10, 30, 100]}
            />
            </div>
        </div>
    );
}