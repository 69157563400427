import React from "react";
import './css/SurveyEmployees.css';
import * as ft from '../utils/fetch';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa';
import EmployeePicker from "./EmployeePicker";
import SurveyTownModal from "./SurveyTownModal";

export default function SurveyEmployees({ role }) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [townModalOpen, setTownModalOpen] = React.useState(false);
    const [empAdd, setEmpAdd] = React.useState('');
    const [sEID, setSEID] = React.useState('');

    const columns = [
        { id: 'first_name', label: 'First', minWidth: 80 },
        { id: 'last_name', label: 'Last', minWidth: 80 },
        { id: 'town_assignment', label: 'Town Assignment', minWidth: 100, align: 'center' },
        ( role >= 1 ? { id: 'edit_btn', label: '', minWidth: 20, align: 'left' } : ''),
        ( role >= 1 ? { id: 'delete_btn', label: '', minWidth: 20, align: 'right' } : ''),
    ];

    // Table Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const generate_delete_button = (id) => { if( role >= 1 ) {  
        return ( 
        <div title='Delete Tester' className='btn-delete-container'>
            <FaTrashAlt 
                size={20} 
                className='btn-delete' 
                onClick={async () => { 
                    if(window.confirm('Are you sure you want to remove this survey employee?')) { remove_tester(id); load_data(); }
                }} 
            />
        </div> ) } else { return <></> }
    };

    const generate_edit_button = (id) => { if( role >= 1 ) {  
        return ( 
        <div title='Edit Town Assignment' className='btn-edit-container'>
            <FaPencilAlt 
                size={20} 
                className='btn-edit' 
                onClick={async () => { setSEID(id) }} 
            /> 
        </div> ) } else { return <></> } 
    };

    // Add employee to table
    const addRow = (pt_obj, emp_obj) => { setRows(rows => [ ...rows, { 
        first_name: emp_obj.first_name, 
        last_name: emp_obj.last_name, 
        town_assignment: pt_obj.town_assignment,
        edit_btn: generate_edit_button(pt_obj._id),
        delete_btn: generate_delete_button(pt_obj._id),
    }])};

    // Load pole tester data
    const load_data = async () => {
        await setRows([]);
        let r = await ft.fetch_get('/get/surveyemployees');
        let e = await ft.fetch_get('/get/employees');

        r.data.forEach(r => {
            e.data.forEach(e => {
                if(e._id === r.employee_id) { addRow(r, e); }
            })
        })
    }

    // Method for adding employee to testing list
    const add_tester = async (id) => {
        let new_tester = { employee_id: id, town_assignment: 'None' };
        let result = await ft.fetch_post('/add/surveyemployee', new_tester);
        if(result.result === 'success') { alert('Success', 'Pole surveyor successfully added.'); }
        else { alert('Failure', 'Pole surveyor failed to be added. Try again.'); }
        setEmpAdd('');
        load_data();
    }

    // Method for removing a tester
    const remove_tester = async (id) => {
        let result = await ft.fetch_delete('/remove/surveyemployee/' + id);
        if(result.result === 'success') { return true }
        else { return false }
    }

    // Method for Updating Town Assignment
    const update_town_assignment = async (emp_id, new_town) => {

        // Grab Employee Object(s)
        let se_obj = await ft.fetch_get(`/get/surveyemployees?uid=${emp_id}`);
        
        if(se_obj.data) {

            // Duplicate List
            let new_list = se_obj.data;

            // Apply new Town Assignment
            for(let i = 0; i < new_list.length; i += 1) {
                new_list[i].town_assignment = new_town;
            }

            // Upload new Objects
            for(let i = 0; i < new_list.length; i += 1) {
                let result = await ft.fetch_post('/update/surveyemployee', new_list[i]);
            }
        }

        setSEID('');
        load_data();
    }

    // Loading Effect
    React.useEffect(() => {
        load_data();
    }, []);

    // Add employee effect
    React.useEffect(() => {
        if(empAdd !== '') { add_tester(empAdd) }
    }, [empAdd])

    // Change Town Effect
    React.useEffect(() => {
        if(sEID === '') { setTownModalOpen(false) } 
        else { setTownModalOpen(true) }
    }, [sEID]);

    return (
        <div className="pt-container">
            <div className="pt-row">
                <div className="add-tester-btn" onClick={ () => setModalOpen(true) }>
                    <span className="add-tester-btn-text">+ Add Surveyor</span>
                </div>
            </div>
            <div className="pt-row">
                <Paper sx={{ width: '100%', overflow: 'scroll', border: '3px solid black' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
            <EmployeePicker
                employee={empAdd}
                setEmployee={setEmpAdd}
                isOpen={modalOpen}
                setOpen={setModalOpen}
            />
            <SurveyTownModal
                applyChange={update_town_assignment}
                surveyEmployeeID={sEID}
                isOpen={townModalOpen}
                setSEID={setSEID}
            />
        </div>
    )
}