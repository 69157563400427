import React from "react";
import './css/Poles.css';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SelectPoleModal from "./SelectPoleModal";
import { FaPlus, FaTrashAlt, FaEdit, FaDirections } from 'react-icons/fa';
import * as ft from '../utils/fetch';

export default function Poles() {

    //=================//
    // State Variables //
    //=================//

    const [searchPoleNumber, setSearchPoleNumber] = React.useState('');
    const [searchTown, setSearchTown] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [packetPage, setPacketPage] = React.useState(0);
    const [packetRowsPerPage, setPacketRowsPerPage] = React.useState(25);
    const [packetRows, setPacketRows] = React.useState([]);
    const [packetEmployee, setPacketEmployee] = React.useState('');
    const [packetTown, setPacketTown] = React.useState('');
    const [packetEmployeeOptions, setPacketEmployeeOptions] = React.useState([]);
    const [packetTownOptions, setPacketTownOptions] = React.useState([]);
    const [packetNameInput, setPacketNameInput] = React.useState('');
    const [selectPoleModalOpen, setSelectPoleModalOpen] = React.useState(false);
    const [selectPolePacketID, setSelectPolePacketID] = React.useState('');

    //==========================//
    // Table Methods and Values //
    //==========================//

    const columns = [
        { id: 'gis_id', label: 'GIS ID', minWidth: 20 },
        { id: 'pole_number', label: 'Pole #', minWidth: 20 },
        { id: 'town', label: 'Town', minWidth: 80 },
        { id: 'state', label: 'State', minWidth: 10 },
        { id: 'latitude', label: 'Lat', minWidth: 80 },
        { id: 'longitude', label: 'Lng', minWidth: 80 },
        { id: 'directions', label: 'Directions', minWidth: 10 },
    ];
    const packetColumns = [
        { id: 'packet_name', label: 'Packet Name', minWidth: 80 },
        { id: 'employee', label: 'Employee', minWidth: 80 },
        { id: 'edit_btn', label: '', minWidth: 10, align: 'right' },
        { id: 'pole_count', label: 'Pole Count', minWidth: 10 },
        { id: 'town', label: 'Town', minWidth: 80 },
        { id: 'del_btn', label: '', minWidth: 10, align: 'center' },
    ];

    // Table Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChangePacketPage = (event, newPage) => {
        setPacketPage(newPage);
    };
    const handleChangePacketRowsPerPage = (event) => {
        setPacketRowsPerPage(+event.target.value);
        setPacketPage(0);
    };

    // Selecter Methods
    const handleTesterChange = (event) => {
        setPacketEmployee(event.target.value);
    };
    const handleTownChange = (event) => {
        setPacketTown(event.target.value);
    };

    // Add report to table
    const addRow = (obj) => { setRows(rows => [ ...rows, { 
        gis_id: obj.gis_id,
        pole_number: obj.pole_number, 
        town: obj.town, 
        state: obj.state,
        latitude: obj.latitude,
        longitude: obj.longitude,
        directions: direction_button(obj.latitude, obj.longitude),
    }])};
    const addPacketRow = (obj) => { setPacketRows(rows => [ ...rows, { 
        packet_name: obj.packet_name, 
        employee: obj.employee, 
        edit_btn: obj.isDownloaded ? '' : edit_button(obj._id),
        pole_count: `${obj.completed_poles}/${obj.poles.length}`,
        town: obj.town,
        del_btn: delete_button(obj._id),
        employee_id: obj.employee_id,
    }])};

    // Table Buttons
    const delete_button = (id) => {
        return ( 
            <div title='Delete Packet'>
                <FaTrashAlt 
                    size={20} 
                    className='p-packet-btn-delete' 
                    onClick={async () => { 
                        delete_pole_packet(id);
                    }} 
                />
            </div> 
        )
    };
    const edit_button = (id) => {
        return ( 
            <div title='Select Poles'>
                <FaEdit 
                    size={20} 
                    className='p-packet-btn-edit' 
                    onClick={async () => { 
                        edit_pole_list(id);
                    }} 
                />
            </div> 
        )
    };
    const direction_button = (lat, lon) => {
        return ( 
            <div title='Get Directions'>
                <FaDirections 
                    size={20} 
                    className='p-btn-direction' 
                    onClick={async () => { 
                        window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`,'_blank');
                    }} 
                />
            </div> 
        )
    };

    //====================//
    // Page Asset Methods //
    //====================//

    // Load Pole Method
    const load_poles = async () => {
        if(searchTown === '') { return alert('You must specify a town to load') }
        await setRows([]);

        let query = `?pole_number=${searchPoleNumber}&town=${searchTown.toUpperCase()}`;

        try {
            let result = await ft.fetch_get(`/get/poles${query}`);
            if(result.data) { result.data.forEach(pole => addRow(pole))}
        } catch(e) { console.warn(e) }
    }

    // Load packet data
    const load_packet_data = async () => {

        // Clear current data
        await setPacketRows([]);

        // Declare arrays
        let towns = [], testers = [], packets = [], employees = [];

        // Grab Employees
        try {
            let r = await ft.fetch_get('/get/employees');
            if(r.data) { employees = r.data }
        } catch(e) { console.warn(e) }

        // Grab towns
        try {
            let r = await ft.fetch_get('/get/pole/towns');
            if(r.data) { towns = r.data }
        } catch(e) { console.warn(e) }

        /*
        // Tally Remaining Inspection Types (SLOW)
        for(let i = 0; i < towns.length; i += 1) {
            let count = await ft.fetch_get(`/get/remaining/poles/${towns[i]}`);
            towns[i] = `${count.FULL ? count.FULL : 0} - ${count.VISUAL ? count.VISUAL : 0} - ${towns[i]}`;
        }
        */

        // Grab Pole Testers
        try {
            let r_pt = await ft.fetch_get('/get/poletesters');
            
            if(r_pt.data) {
                r_pt.data.forEach( pt => {
                    employees.forEach( emp => {
                        if(pt.employee_id === emp._id) { testers.push(emp) }
                    })
                })
            }
        } catch(e) { console.warn(e) }

        // Grab Pole Packets
        try {
            let r = await ft.fetch_get('/get/polepacket');
            if(r.data) { packets = r.data }
        } catch(e) { console.warn(e) }

        // Update Packet Progresses
        try {
            for(let i = 0; i < packets.length; i++) {
                if(Array.isArray(packets[i].poles) && packets[i].poles.length > 0) {
                    
                    // Grab Poles from Packets
                    let r = await ft.fetch_post('/get/poles/many', { 'poles': packets[i].poles });

                    // Set progress to 0
                    packets[i].completed_poles = 0;

                    // If we find poles, count how many have a status = 2
                    if(r.data && Array.isArray(r.data)) {
                        r.data.forEach(pole => {
                            if(pole.status === 2) { packets[i].completed_poles += 1; }
                        });
                    }
                } else {
                    packets[i].completed_poles = 0;
                }
            }
        } catch(e) { console.warn(e) }

        // Set names in packets
        packets = packets.map( packet => {
            packet.employee = employees.filter(emp => emp._id === packet.employee_id)[0];
            packet.employee = `${packet.employee.first_name} ${packet.employee.last_name}`
            return packet;
        })

        // Add packets to table
        packets.forEach( async packet => await addPacketRow(packet) )

        // Set States
        setPacketTownOptions(towns);
        setPacketEmployeeOptions(testers);
    }

    // Start Creating a new Pole Packet
    const new_pole_packet = async () => {

        // Check for empty form inputs
        if(packetTown === '' || packetEmployee === '' || packetNameInput === '') { return alert('Please choose an employee, town, and packet name.')}
        
        // Create the new object for saving to database
        let new_obj = {
            packet_name: packetNameInput,
            employee_id: packetEmployee,
            town: packetTown,
            poles: [],
            isDownloaded: false,
        }

        // Upload Pole Packet
        try {
            let result = await ft.fetch_post('/add/polepacket', new_obj);

            // Repond with results
            if(result.result === 'success') { 
                load_packet_data();
                return alert('Packet created successfully.') 
            }

        // log any critical errors
        } catch(e) { console.warn(e) }
    }

    // Delete a Pole Packet
    const delete_pole_packet = async (id) => {

        // Prompt User for Confirmation
        if(window.confirm('Are you sure you want to delete this packet?')) {
            
            // Remove Packet
            try {
                let result = await ft.fetch_delete(`/remove/polepacket/${id}`);

                // Alert to Errors
                if(result.result !== 'success') {
                    return alert('ERROR: ' + result.result);
                
                // Reload packet list
                } else { load_packet_data() }

            // Log critical errors
            } catch(e) { console.warn(e) }
        }
    }

    // Edit Pole Packet List
    const edit_pole_list = async (id) => {
        setSelectPolePacketID(id);
    }

    //===============//
    // React Effects //
    //===============//

    // Effects
    React.useEffect(() => { load_packet_data() }, []);
    React.useEffect(() => { if(selectPolePacketID !== '') { setSelectPoleModalOpen(!selectPoleModalOpen) } }, [selectPolePacketID])

    //=======================//
    // Render Page Component //
    //=======================//

    return (
        <div className="p-container">
            <div className="p-control-row">
                <TextField
                    InputProps={{ className: 'text-field' }} 
                    id="pole_number" 
                    label="Pole Number" 
                    variant="outlined" 
                    value={searchPoleNumber} 
                    onChange={(v) => setSearchPoleNumber(v.target.value)}
                />
                <TextField
                    InputProps={{ className: 'text-field' }} 
                    id="town" 
                    label="Town" 
                    variant="outlined" 
                    value={searchTown} 
                    onChange={(v) => setSearchTown(v.target.value)}
                />
                <div className="p-control-apply-btn" onClick={() => load_poles()}>
                    <span className="p-control-apply-btn-text">
                        Apply
                    </span>
                </div>
            </div>
            <div className="p-pole-table-container">
                <Paper sx={{ width: '100%', overflow: 'scroll', border: '3px solid black' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
            <div className="p-packet-control-row">
                <FormControl sx={{ marginRight: '10px', flex: 1, }} >
                    <InputLabel id="packet-employee-label">Employee</InputLabel>
                    <Select
                        labelId="packet-employee-label"
                        id="packet-employee"
                        value={packetEmployee}
                        label="Employee"
                        onChange={handleTesterChange}
                    >
                        {packetEmployeeOptions.map( emp => <MenuItem value={emp._id}>{`${emp.first_name} ${emp.last_name}`}</MenuItem> )}
                    </Select>
                </FormControl>
                <FormControl sx={{ marginRight: '10px', flex: 1, }} >
                    <InputLabel id="packet-town-label">Town</InputLabel>
                    <Select
                        labelId="packet-town-label"
                        id="packet-town"
                        value={packetTown}
                        label="Town"
                        onChange={handleTownChange}
                    >
                        {packetTownOptions.map( town => <MenuItem value={town}>{town}</MenuItem> )}
                    </Select>
                </FormControl>
                <TextField
                    sx={{ flex: 1 }}
                    InputProps={{ className: 'text-field-packet' }} 
                    id="packet_name" 
                    label="Packet Name" 
                    variant="outlined" 
                    value={packetNameInput} 
                    onChange={(v) => setPacketNameInput(v.target.value)}
                />
                <div className="packet-add-btn">
                    <FaPlus size={40} color="green"  
                        title="New Pole Packet" 
                        onClick={() => new_pole_packet()} 
                        style={{cursor: 'pointer'}} 
                    />
                </div>
            </div>
            <div className="p-packet-pole-packet-table">
                <Paper sx={{ width: '100%', overflow: 'scroll', border: '3px solid black' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {packetColumns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {packetRows.filter( packet => (packetEmployee ? packet.employee_id === packetEmployee : true))
                            .slice(packetPage * packetRowsPerPage, packetPage * packetRowsPerPage + packetRowsPerPage)
                            .map((row) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {packetColumns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={packetRows.length}
                        rowsPerPage={packetRowsPerPage}
                        page={packetPage}
                        onPageChange={handleChangePacketPage}
                        onRowsPerPageChange={handleChangePacketRowsPerPage}
                    />
                </Paper>
            </div>
            <SelectPoleModal 
                packet_id={selectPolePacketID}
                isOpen={selectPoleModalOpen}
                setPacketID={setSelectPolePacketID}
                loadPacketData={() => load_packet_data()}
            />
        </div>
    )
}