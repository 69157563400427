import './Map.css';
import React from 'react';
import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api';

const mapOptions = {
    disableDefaultUI: true,
    clickableIcons: false,
    fullscreenControl: true,
    mapId: '2b5188176d69936b',
};

export default function Map({poles, key}) {

    const [activeMarker, setActiveMarker] = React.useState(null);
    const clustererRef = React.useRef();

    const parse_poles = (poles) => {
        return poles.map( pole => ({
            id: pole.pole_number + pole.town + pole.state,
            name: pole.pole_number,
            town: pole.town,
            state: pole.state,
            location : {
                lat: parseFloat(pole.latitude),
                lng: parseFloat(pole.longitude)
            }
        }))
    }

    const calc_center = (poles) => {
        let lat = 0, lng = 0;
        poles.forEach( pole => { lat += pole.latitude; lng += pole.longitude });
        return { lat: lat / poles.length, lng: lng / poles.length };
    }
    
    const markers = parse_poles(poles);
    const center = calc_center(poles);

    React.useEffect(()=>{
        if(clustererRef.current) { clustererRef.current.repaint() }
    }, [])


    return (
        <div className="map-container">
            <GoogleMap
                key={key}
                zoom={14}
                center={activeMarker ? markers.find( m => m.id === activeMarker).location : center}
                mapContainerClassName='map'
                options={mapOptions}
            >
                <MarkerClusterer
                    ref={clustererRef}
                    onLoad={clusterer => (clustererRef.current = clusterer)}
                >
                    {(clusterer) => 
                        markers.map(({ id, name, location, town, state }) => <Marker 
                            position={location} 
                            icon={{
                                url: 'https://easapi.enviroarborsolutions.com/svgs/utility_pole_red.svg',
                                scaledSize: { width: 32, height: 32 }
                            }}
                            clusterer={clusterer}
                            onClick={() => setActiveMarker(id)}
                        >
                            {activeMarker === id ? (
                                <InfoWindow 
                                    position={location}
                                >
                                    <div className="pole-map-info-window-container">
                                        <span className='pole-map-info-window-pole-number-text'>{name}</span>
                                        <span className='pole-map-info-window-town-state-text'>{`${town} ${state}`}</span>
                                    </div>
                                </InfoWindow>
                            ) : ''}    
                        </Marker>)
                    }
                </MarkerClusterer>
            </GoogleMap>
        </div>
    )
}